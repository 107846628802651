/*
 ==== Color Palette ==========================
*/
// Override Bootstrap defaults
$blue: #0081c6;
$red: #f00;
$blue-layer-1: #5bc0de40;
$white: #fff;
$black: #000;
$green: #789b4a;
$secondary-blue: #376baf;
$gray-primary: #939ca0;

$light: $white;
$dark: $black;
$secondary-dark: #858d90;
$primary: $green;
$secondary: $secondary-dark;
$tertiary: $gray-primary;

// Lew's custom colors
// Question,  should we override bootstrap grays?
//   dark -> gray-900
//   primary -> gray-800
//   secondary -> gray-600
//   ghost -> gray-100
$gray-dark: $black;
$gray-secondary: #78777a;
$gray-tertiary: #788084;
$korum-purple: #854c8e;
$gray-ghost: #f8f9fa;
$semi-dark: $secondary;
$gray-light: #f2f1f1;
$gray-medium: #bcbec0;
$checkbox-fill-color: $primary;

$info: $primary;
$success: $light;
$danger: $red;

$text-highlight: rgba(215, 239, 255, 0.75);
/*
 ==== Fonts ==============================
*/
$primary-font: 'Roboto-Condensed', sans-serif;
$secondary-font: 'Roboto-Condensed', sans-serif;
$tertiary-font: 'Roboto-Condensed', sans-serif;
$font-family-base: $primary-font !default;
$font-weight-bolder: 900 !default;
$font-size-base: 1.2rem; // Assumes 16px base * 1.2 = 19ish
$font-size-sm: 0.875rem;
$input-btn-font-size: 1.188rem;
$input-btn-font-size-sm: 1rem;
$input-btn-font-size-lg: 1.438rem;
$line-height-base: 1.4;
$enable-responsive-font-sizes: true !default;

// Default css variable fonts
:root {
    --primary-font: #{$primary-font};
    --secondary-font: #{$secondary-font};
    --tertiary-font: #{$tertiary-font};
    --font-family-base: #{$primary-font};
    --primary-contrast: #{$light};
    --secondary-contrast: #{$light};
    --checkbox-fill-color: var(--primary);
    --grid-odd-background: #{$gray-light};
    --btn-font-size: #{$input-btn-font-size};
    --btn-font-size-lg: #{$input-btn-font-size-lg};
    --btn-font-size-sm: #{$input-btn-font-size-sm};
}

$h1-font-size: 62px;
$h2-font-size: 53px;
$h3-font-size: 45px;
$h4-font-size: 38px;
$h5-font-size: 30px;
$h6-font-size: 23px;

$h1-mobile-font-size: 53px;
$h2-mobile-font-size: 45px;
$h3-mobile-font-size: 38px;
$h4-mobile-font-size: 30px;
$h5-mobile-font-size: 23px;
$h6-mobile-font-size: 19px;

$headings-font-weight: 700 !default;

$display1-size: 120px;
$display2-size: 90px;
$display3-size: 38px;
$display4-size: 19px;

$display1-weight: 900;
$display2-weight: 900;
$display3-weight: 900;
$display4-weight: 900;

/*
 ==== Links  ==============================
*/
//$link-color: $purple;
//$link-decoration: underline !default;
/*
 ==== Navigation  ==============================
*/
$navbar-dark-active-color: $white;
$navbar-dark-hover-color: $green;
$navbar-dark-color: $green;
/*
 ==== Buttons  ==============================
*/
$input-btn-font-family: 'Roboto-Condensed', sans-serif !default;
$btn-font-weight: 700;
$btn-border-radius: 0px;
$btn-border-radius-lg: 0px;
$btn-border-radius-sm: 0px;
$btn-line-height: 18px;
$btn-line-height-lg: 22px;

/*
 ==== Input  ==============================
*/
$input-font-size: 24px !default;
$input-border-color: $black;
$input-border-width: 2px;
$input-border-radius: 0px;
$input-border-radius-lg: 0px;
$input-border-radius-sm: 0px;
/*
 ==== Alerts  ==============================
*/
$alert-border-radius: 0px;
$alert-border-width: 2px !default;
$alert-border-level: 0 !default;
/*
 ==== Forms  ==============================
*/
$enable-validation-icons: false;
$form-feedback-valid-color: $black;

/*
=== Breakpoints ===========================
*/
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1350px,
) !default;
