﻿.social {
    &__link {
        line-height: 35px;
        display: inline-flex;
        align-items: center;
        &:not(:first-child) {
            margin-left: 5px;
        }
    }
}
