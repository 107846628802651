@import '../../../../../Common/src/scss/components/react/LanguageSelector.scss';

.LanguageSelector {
    > button {
        border: none;
        color: $white;
    }

    &.account-settings {
        > button {
            color: $gray-dark;
            border: 2px solid $gray-dark;
        }
    }
}
