@import '../../../Common/src/scss/blocks/_newslettersubscriptionblock.scss';

.newsletter__subscription {
    button[type='submit'] {
        padding: 10px 17px;

        @include media-breakpoint-down(md) {
            padding: 5px 17px !important;
        }
    }
}
