.prostaff__cardrow,
.prostaff__cardgrid {
    .filter {
        .dropdown-menu {
            background-color: $gray-medium;
            color: $black;

            input[type='checkbox'] {
                border: 2px solid $black !important;
            }
        }
    }

    .sort {
        .dropdown-menu.dropdown__drawer {
            background-color: $gray-medium;
            color: $black;
            border-radius: 0;

            .dropdown-item {
                color: $black;
                background-color: $gray-medium;
                &:hover,
                &:active {
                    color: $black;
                    background-color: $gray-ghost;
                }
            }
        }
    }

    .card {
        .card-img-top {
            filter: brightness(60%);
        }

        .title {
            @include font-size(24px, true);
            font-weight: 700 !important;
            word-spacing: normal !important;
        }

        .subtitle {
            font-weight: 600;
            @include font-size(12px, true);
            text-transform: uppercase;
            letter-spacing: normal;
        }
    }
}

.prostaff__cardrow {
    .card {
        .card-img {
            filter: brightness(60%);
        }

        > a {
            margin-left: 0 !important;
        }
    }
}
