﻿@import '../../../../../Common/src/scss/components/react/CatalogGrid.scss';

.CatalogGrid {
    .CatalogGrid__load-more-container {
        > button {
            font-weight: bold;
            padding: 0.75rem 1.5rem;
            line-height: calc(1em - 1px);
            background: $dark;
            border-color: $dark;
            color: $white;
        }
    }
}
