﻿@import '../../../../Common/src/scss/sections/_header.scss';

.header {
    &__items {
        padding: 1rem 0rem;
        display: grid;
        grid-auto-columns: 33%;
        grid-auto-rows: 1fr;
        align-items: center;
    }

    &__search {
        grid-column-start: 1;
    }

    &__logo {
        grid-column-start: 2;
        min-height: 50px;
        display: flex;
        justify-content: center;
    }

    &__account {
        grid-column-start: 3;
        display: flex;
        align-items: center;
    }
}

.header__prebanner {
    background: var(--primary);
    height: 30px;
}
.header__banner {
    text-align: center !important;
    color: $white;
    @include font-size(14);
    background: $dark;
    text-align: center;
}
.header__section {
    background-color: $dark;

    .header__user button,
    a.header__login {
        @extend .paragraph-3;
        font-family: var(--tertiary-font);
    }

    .search-button {
        border: none !important;
        background-color: $dark !important;
        color: $white !important;
        font-size: 16px !important;
    }

    .header_user {
        button {
            i.fa-chevron-down {
                font-size: 14px;
            }
        }
    }

    .search {
        font-family: var(--tertiary-font);
        @include font-size(14);
        letter-spacing: 0.25px;
        text-transform: none !important;

        span {
            pointer-events: none;
        }
    }

    .header__social__item {
        .social__link {
            .social__icon > i {
                .icon-tiktokicon {
                    color: $white;
                    height: 35px !important;
                    margin-bottom: 5px;
                    margin-left: -10px;
                    margin-right: -10px;
                    width: 35px !important;
                }

                .icon-tiktokicon:hover {
                    color: var(--primary);
                }
            }
        }
    }
}

.header__social {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 1.25rem;
    width: 100%;
    li {
        display: inline-block;

        a {
            color: $white;
            @include font-size(24px);
            height: 35px;
            width: 35px;

            &:active,
            &:hover,
            &:focus {
                color: var(--primary);
                text-decoration: none;
                outline: 0;
            }
        }
    }
}

.header__my-account-container {
    position: absolute;
    right: 0;
    top: 0;
    color: $white;
    height: 100%;

    button.header__minicart {
        background: transparent;
        padding-left: 15px;
        border: none;
    }
}

.header__usermenu {
    a {
        &:hover {
            text-decoration: none;
        }
    }
}

.header__logo {
    position: relative;
    z-index: 1;
}
