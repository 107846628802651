.navigation__list {
    padding-top: 10px !important;
    padding-bottom: 10px !important;

    & .dropdown-item {
        font-family: var(--secondary-font);
        @include font-size(16px);
        letter-spacing: 2px;
        color: $dark;

        &:hover,
        &:focus {
            text-decoration: underline;
            background-color: var(--primary);
            color: $white;
        }
    }
}
