@import '../../../Common/src/scss/components/react/SparePartsTable.scss';

.SparePartsTable {
    .Grid {
        &__cell {
            &--header {
                color: var(--light);
            }
        }
    }
}
