﻿@import '../../../../../Common/src/scss/components/react/ProductTabContent.scss';

.ProductTabContent {
    .nav-item {
        a {
            font-size: 24px !important;
            font-weight: bold;
            color: $dark !important;

            &:active,
            &:focus {
                text-underline-offset: 10px !important;
                text-decoration: underline !important;
                text-decoration-thickness: 2px !important;
                text-decoration-color: $korum-purple !important;
            }
        }
    }
}
