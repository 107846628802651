﻿@import '../../../../../Common/src/scss/components/react/ProductView.scss';

.ProductView {

    .ProductTitle {
        p {
            font-size: 3.875rem;
            text-transform: uppercase;
            font-weight: $headings-font-weight;
            margin-bottom: 0.5rem;
            line-height: 1.2;
        }
    }

    @include media-breakpoint-down(sm) {
        .ProductTitle {
            p {
                font-size: calc(1.325rem + 0.9vw);
            }
        }
    }
}
