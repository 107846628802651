﻿@import '../../../../../Common/src/scss/components/react/SearchResults.scss';

.SearchResults {
    .SearchBar {
        position: relative;
    }

    &__left-panel {
        padding: 10%;
        background: $gray-light !important;

        .SearchFacets {
            padding-bottom: 20px;
            border-bottom: 2px solid lightgray;
            margin-bottom: 30px;

            .FacetFilter {
                padding: 10px 0px;

                label {
                    display: flex;
                    align-items: center;
                }

                .subheader {
                    color: $semi-dark;
                    @include font-size(19px);
                    cursor: pointer;
                    line-height: 0.9em;
                }

                input[type='checkbox'],
                input[type='radio'],
                div.input-checkbox,
                div.input-radio {
                    height: 18px;
                    min-height: 18px;
                    width: 18px;
                    min-width: 18px;
                    background: transparent;
                }

                button.title {
                    width: 100%;
                    padding: 5px 0px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    &[aria-expanded='true'] {
                        .fa.fa-chevron-down {
                            transform: scaley(-1);
                        }
                    }
                }

                .dropdown-drawer {
                    padding-top: 10px;

                    button {
                        width: 100%;
                        text-align: left;
                    }
                }

                label {
                    span.subheader {
                        font-weight: 400;
                        @include font-size(1rem);
                        font-weight: 400;
                    }
                }
            }
        }

        .SearchLinks {
            &__link {
                .subheader {
                    color: $semi-dark;
                    @include font-size(1rem);
                    cursor: pointer;
                    font-weight: 400;

                    &.header {
                        cursor: auto;
                        @include font-size(19px);
                        line-height: inherit !important;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}
