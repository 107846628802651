.prostaffPage {
    .prostaffPage__title {
        @include font-size(36px);
        font-weight: 600;
        letter-spacing: 0;
    }

    .prostaffPage__protitle {
        @include font-size(16px);
        font-weight: 600;
    }

    .prostaffPage__awards {
        p {
            @include font-size(14px, true);
            font-weight: 500;
        }
    }

    .prostaffPage__sectiontitle {
        @include font-size(24px);
        font-weight: 600;
    }

    .quote-box {
        h2 {
            line-height: 0.9em !important;
        }
    }

    .ProductSlider {
        .ProductRating {
            display: none !important;
            // margin-bottom: 1rem !important;
            // & > div {
            //     display: none !important;
            // }
        }

        .subheader {
            @include font-size(16px);
            font-weight: bold;
            margin: 1rem 0 !important;
            text-align: center;
        }
    }
}
