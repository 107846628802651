﻿@import '../../../../Common/src/scss/components/_menucta.scss';

.menucta__horizontal {
    font-family: var(--secondary-font);
    @include font-size(14px);
    letter-spacing: 2px;
    color: $dark;

    &:hover,
    &:focus {
        color: var(--primary);
    }

    & .menucta__links {
        padding: 0px 10px;
    }

    & .menucta__action {
        .menucta__action__item {
            h3 {
                font-family: var(--secondary-font);
                font-size: 24px;
                letter-spacing: 2px;
            }

            h6 {
                font-family: var(--secondary-font);
                font-size: 20px;
                letter-spacing: 0.29px;
                line-height: 20px;
            }
        }
    }
}

.menucta__vertical {
    font-family: var(--secondary-font);
    @include font-size(14px);
    letter-spacing: 2px;
    color: $dark;

    &:hover,
    &:focus {
        color: var(--primary);
    }

    .menuctalinksblock {
        padding: 0 !important;
    }

    .menuctaactionblock {
        padding: 0 !important;

        .menucta__action {
            .menucta__action__item {
                h3 {
                    font-family: var(--secondary-font);
                    font-size: 24px;
                    letter-spacing: 2px;
                }

                h6 {
                    font-family: var(--secondary-font);
                    font-size: 20px;
                    letter-spacing: 0.29px;
                    line-height: 20px;
                }
            }
        }
    }
}

.navigation__mobile__child-container {
    .call-to-action {
        .menuctalinksblock {
            padding-left: 0;
            padding-right: 0;

            .menucta__links__mobile {
                padding-left: 0;
                padding-right: 0;

                .menulink__item {
                    justify-content: left;

                    img {
                        filter: invert(1);
                    }

                    .title {
                        font-family: var(--secondary-font);
                        @include font-size(16px);
                        letter-spacing: 2px;
                        color: $white;
                    }

                    .subtitle {
                        @include font-size(14px);
                        letter-spacing: 0.29px;
                        line-height: 20px;
                        color: $white;
                    }
                }
            }
        }

        .menuctaactionblock {
            padding-left: 0;
            padding-right: 0;

            & .menucta__action {
                padding-left: 0;
                padding-right: 0;

                & .menucta__action__item {
                    justify-content: left;

                    h3 {
                        font-family: var(--secondary-font);
                        font-size: 26px;
                        letter-spacing: 2px;
                    }

                    h6 {
                        font-family: var(--secondary-font);
                        font-size: 20px;
                        letter-spacing: 0.29px;
                        line-height: 20px;
                    }
                }
            }
        }
    }
}

.menucta__action {
    .card {
        min-width: 175px;
    }
}
